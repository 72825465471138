import React from 'react';
import './Header.css';

const Header = () => {
  // Smooth scroll to section function
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className='header'>
      <div className='logo'>
        <img src='/icons/logo.png' alt='Jerry Logo' />
      </div>
      <nav className='navbar'>
        {/* Use onClick to call the scrollToSection function with the corresponding id */}
        <a href='#about' onClick={() => scrollToSection('about')}>
          about
        </a>
        <a href='#technologies' onClick={() => scrollToSection('technologies')}>
          technologies
        </a>
        <a href='#projects' onClick={() => scrollToSection('projects')}>
          projects
        </a>
        <a
          href='#contact'
          onClick={() => scrollToSection('contact')}
          className='contact-link'
        >
          Let's talk
        </a>
      </nav>
    </header>
  );
};

export default Header;
